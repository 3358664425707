import Layout from "../../components/Layout/formHeader";
import Content from "../../common/Content";
import * as React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'


export default function PrivacyPolicy() {

    return(
        <div className="page-container">
        <Header tagline={Content.Header.tagline}/>
        <Layout header="Data Policy">
        <div className="grid">
        <div className="col-m-12">
            <h2>How we use your data.</h2>
        <p><strong>WE DON'T</strong><br/> I'll say that again, we don't use your data, it's not saved anywhere on any of our servers, there is no database behind this site, the whole
        point of this website is to generate passwords that are secure and more importantly are not saved anywhere. Nobody can get at your password by hacking into our server
        because we don't save it.</p><br />
        
        <p>The website started way back in 2008 as a private project to enable Charlie to get passwords for any of the websites that he used without actually having to
        remember anything. The whole concept behind the website was to not store the generated password anywhere, if you forget your own password, hard luck, don't ask us
        to retrieve it as we can't, it should only ever be in your head.
        </p>
        <p>So that's it basically, we don't use your data - ever</p>
        <p>There is of course one caveat, if in the fullness of time we add any third party information to the site, ads or whatever then they obviously will use your data. However
        that is between you and the third party</p>
        </div>
        </div>
        </Layout>
        <Footer/></div>

    );    
}