import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from './features/Home';
import CookiePolicy from './features/CookiePolicy';
import PrivacyPolicy from './features/PrivacyPolicy';
import How from './features/How';
import ScrollToTop from './components/ScrollToTop';

const Router = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <ScrollToTop />                    
            <Routes>
                 <Route path="/" element={<Home />}/>
                 <Route path="/cookie-policy" element={<CookiePolicy />}/>
                 <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                 <Route path="/how-does-it-work" element={<How />}/>
                 <Route path="*" element={<Navigate to="/" />}/>
            </Routes>        
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Router;