import { encrypt, pending, encryptError } from '../../state/reducers/encryptSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse, AxiosError }  from 'axios';
import { IEncryptValues } from '../../interfaces';
import { PROD_API_URL } from '../../common'

const API_URL = process.env.NODE_ENV==="development" ? (process.env.REACT_APP_USE_PROD ? PROD_API_URL : process.env.REACT_APP_API) : process.env.REACT_APP_API ;

export function getEncryption(encryptValues: IEncryptValues | null) {
    
    return (dispatch:(arg0: PayloadAction<any>) => any) => {
        dispatch(pending());
        axios.post(`${API_URL}/api/encryption`, encryptValues)
        .then((res: AxiosResponse) => {
            dispatch(encrypt(res.data))
        }).catch((error: AxiosError) => {
            dispatch(encryptError(error.response?.data))
        })
    }
}
