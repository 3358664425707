import { IEncryptValues } from "../interfaces";

export const ENCRYPT_VALUES: IEncryptValues ={
    text: "",
    password: "",
    password2: "",
    length: 12,
    excludeSymbol: false,
    includeCase: false,
    includeNumber: false,
    includeSymbol: false
}