import * as React from 'react';
import FormHeaderLayout from "../Layout/formHeader";
import Content from "../../common/Content";
import { IEncryptValues } from '../../interfaces'
import { getEncryption } from '../../api/Encryption';
import { IAppState } from '../../state';
import { Button, Form, InputGroup, Overlay, Popover, OverlayProps } from 'react-bootstrap';
import { ENCRYPT_VALUES } from '../../common/shared';
import { encryptReset } from '../../state/reducers/encryptSlice'
import { setAutocomplete } from '../../state/reducers/autocompleteSlice'
import { SpinnerComponent } from 'react-element-spinner';
import "./style.scss";
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from '../../hooks'


export default function EncryptForm() {

    //SETUP
    const
        dispatch = useAppDispatch(),        
        encryption: { content: string, error?: any, loading: boolean } = useAppSelector(({ encryptSlice }: IAppState) => encryptSlice),
        autocomplete: { content: string[], error?: any, loading: boolean } = useAppSelector(({ autocompleteSlice }: IAppState) => autocompleteSlice),
        [result, setResult] = React.useState(Content.EncryptForm.header),
        [encryptValues, setEncryptValues] = React.useState<IEncryptValues>(ENCRYPT_VALUES),
        [showCopy, setShowCopy] = React.useState(false),
        [showOverlay, setShowOverlay] = React.useState(false),
        [target, setTarget] = React.useState(null),
        [toolTip, setTooltip] = React.useState({ title: "", text: "" }),
        [placement, setPlacement] = React.useState<OverlayProps["placement"]>("left"),
        [showPassword, setShowPassword] = React.useState(false),        
        cnt = Content.EncryptForm,
        ref = React.useRef(null),
        runOnce = React.useRef(false),
        textInputRef = React.useRef<HTMLInputElement>(null),                
        [autoOptions, setAutoOptions] = React.useState<any>([]),
        cookies = new Cookies(),      
        getLengthOptions = () => {
            const options: any = [];
            for (let x = 1; x <= 30; x++) {
                options.push(<option key={x}>{x}</option>);
            }
            return options;
        };


    //FUNCTIONS
   
    const updateValues = (values: IEncryptValues) => {
        let newValues: IEncryptValues = {
            text: values.text,
            password: values.password,
            password2: values.password2,
            length: values.length,
            excludeSymbol: values.excludeSymbol,
            includeCase: values.includeCase,
            includeNumber: values.includeNumber,
            includeSymbol: values.includeSymbol
        };

        setEncryptValues(newValues);
    },
        handleSubmit = () => {
            onCopy();
            dispatch(getEncryption(encryptValues));
            const current = new Date();
            const expires = new Date();
            expires.setDate(current.getFullYear() + 5 );
            const maxAge = 5 * 365 * 86400;
            var textList = cookies.get(`text-input-list`);
            var textInput = textList === undefined ? encryptValues.text : textList.includes(encryptValues.text) ? textList : `${textList},${encryptValues.text}`;
            // dispatch({ type: SET_AUTOCOMPLETE, payload: textInput.split(',') })
            dispatch(setAutocomplete(textInput.split(',')))
            //cookie.save(`text-input-list`, textInput, { path: '/',expires: expires});
            cookies.set('text-input-list', textInput, {path: '/',expires, maxAge: maxAge});
            cookies.set(`${encryptValues.text}_length`, encryptValues.length, { path: '/' ,expires: expires, maxAge: maxAge});
            cookies.set(`${encryptValues.text}_includeCase`, encryptValues.includeCase ? "true" : "false", { path: '/',expires: expires, maxAge: maxAge });
            cookies.set(`${encryptValues.text}_includeSymbol`, encryptValues.includeSymbol ? "true" : "false", { path: '/',expires: expires, maxAge: maxAge });
            cookies.set(`${encryptValues.text}_includeNumber`, encryptValues.includeNumber ? "true" : "false", { path: '/',expires: expires, maxAge: maxAge });
            cookies.set(`${encryptValues.text}_excludeSymbol`, encryptValues.excludeSymbol ? "true" : "false", { path: '/',expires: expires, maxAge: maxAge});
        },
        handleClear = () => {
            setEncryptValues(ENCRYPT_VALUES);
            setResult(Content.EncryptForm.header);
            setShowCopy(false);
            dispatch(encryptReset())
        },
        onCopy = () => {
            setEncryptValues(ENCRYPT_VALUES);
            textInputRef.current && textInputRef.current.focus();
        },
        handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {            
            if (e.key === "Enter")//enter
            {
                handleSubmit();
            }
        },
        handleTextBlur = () => {
            //console.log(`Autocomplete: ${JSON.stringify( autocomplete.content)}`)
            var inputLength = setInputLength()
            var includeNumber = cookies.get(`${encryptValues.text}_includeNumber`);
            var includeCase = cookies.get(`${encryptValues.text}_includeCase`);
            var includeSymbol = cookies.get(`${encryptValues.text}_includeSymbol`);
            var excludeSymbol = cookies.get(`${encryptValues.text}_excludeSymbol`);
            let newValues: IEncryptValues = {
                text: encryptValues.text,
                password: encryptValues.password,
                password2: encryptValues.password2,
                length: inputLength,
                excludeSymbol: excludeSymbol === "true",
                includeCase: includeCase === "true",
                includeNumber: includeNumber === "true",
                includeSymbol: includeSymbol === "true"
            };
            setEncryptValues(newValues);
        },
        setInputLength = (): number => {
            var cookieLength = cookies.get(`${encryptValues.text}_length`);
            var passwordLength = cookieLength && parseInt(cookieLength);
            return isNaN(passwordLength) ? 12 : passwordLength;
        },
        handleOverlay = (event: any, tipTitle: string, tipText: string, tipPlacement: OverlayProps["placement"]) => {
            const tip = { title: tipTitle, text: tipText }
            setTooltip({ ...tip });
            setShowOverlay(!showOverlay);
            setTarget(event.target)
            setPlacement(tipPlacement);
        },
        toggleShow = (show: boolean) => {
            setShowPassword(show);
        },
        getAutoCompleteOptions = () => {
            const options: any = [];
            for (let x = 0; x < autocomplete.content.length; x++) {
                options.push(<option key={x} value={autocomplete.content[x]}/>);
            }
            return options;
        };

    //HOOKS
    React.useEffect(() => {
        if (!runOnce.current) {            
            var textList = cookies.get(`text-input-list`);
            var arr = textList ? textList.split(','): [];
            // dispatch({ type: SET_AUTOCOMPLETE, payload: arr })
            dispatch(setAutocomplete(arr));
            runOnce.current = true;
        }
        else{  
            // console.log(`getAutoCompleteOptions called`)          
            setAutoOptions(getAutoCompleteOptions);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocomplete, dispatch])

    React.useEffect(() => {
        if (encryption.content.length > 0) {
            setResult(encryption.content);
            setShowCopy(true);
            onCopy();
        }
        else {
            setResult(Content.EncryptForm.header)
            setShowCopy(false);
        }
    }, [encryption.content])
    
    React.useEffect(() => {
        textInputRef.current && textInputRef.current.focus();
    },[])

    return (
        <FormHeaderLayout
            header={result}
            onCopy={onCopy}
            showCopy={showCopy}
        >
            <SpinnerComponent
                loading={encryption.loading}
                position='global'
                message="Encrypting your password..."
            />

            <div ref={ref}>                
                <Form onSubmit={handleSubmit} autoComplete='on'>                    
                    <div className='mb-3'>
                        <Form.Group >                            
                            <InputGroup className='vertical-align'>
                                <Form.Control
                                    tabIndex={1}
                                    type="text"
                                    name="text-input"
                                    id="text-input"
                                    size='lg'
                                    onChange={e => updateValues({
                                        text: e.target.value,
                                        password: encryptValues.password,
                                        password2: "",
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: encryptValues.includeSymbol
                                    })}
                                    onBlur={e => { e.preventDefault(); e.stopPropagation(); handleTextBlur() }}                                    
                                    value={encryptValues.text}
                                    autoComplete="off"
                                    placeholder='Text'                                    
                                    ref={textInputRef}
                                    title={cnt.text}
                                    list='autocomplete'
                                />
                                <datalist id='autocomplete'>
                                    {autoOptions}
                                </datalist>
                                <div><i className="las la-info-circle la-lg ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Text", cnt.text, "left") }} /></div>
                                
                            </InputGroup>                            
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <InputGroup>
                            <Form.Control
                                tabIndex={2}
                                type={showPassword ? "text" : "password"}
                                size='lg'
                                onBlur={e => { e.preventDefault(); toggleShow(false) }}
                                onChange={e => updateValues({
                                    text: encryptValues.text,
                                    password: e.target.value,
                                    password2: "",
                                    length: encryptValues.length,
                                    excludeSymbol: encryptValues.excludeSymbol,
                                    includeCase: encryptValues.includeCase,
                                    includeNumber: encryptValues.includeNumber,
                                    includeSymbol: encryptValues.includeSymbol
                                })}
                                onKeyPress={handleKeyPress}
                                value={encryptValues.password}
                                placeholder='Password'
                                className='mb-3 password'
                                aria-describedby="buttonShow"
                                autoComplete='off'
                                id="password"
                                name="password"
                                required
                            />
                            <div className="input-group-append">
                                <button type="button" id="buttonShow" name="buttonShow" onClick={e => { e.preventDefault(); toggleShow(!showPassword) }} className={showPassword ? "las la-eye-slash" : "las la-eye"} /></div>
                            <i className="las la-info-circle la-lg ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Password", cnt.password, "left") }} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Length</Form.Label>
                        <InputGroup className='small-select'>
                            <Form.Select
                                tabIndex={3}
                                size='lg'
                                value={encryptValues.length}
                                onChange={e => updateValues({
                                    text: encryptValues.text,
                                    password: encryptValues.password,
                                    password2: encryptValues.password2,
                                    length: parseInt(e.target.value),
                                    excludeSymbol: encryptValues.excludeSymbol,
                                    includeCase: encryptValues.includeCase,
                                    includeNumber: encryptValues.includeNumber,
                                    includeSymbol: encryptValues.includeSymbol
                                })}>
                                {getLengthOptions()}
                            </Form.Select>
                            <i className="las la-info-circle la-lg ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Length", cnt.length, "right") }} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Label>Password must contain:
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={4}
                                    inline
                                    label="Numbers"
                                    checked={encryptValues.includeNumber}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: e.currentTarget.checked,
                                        includeSymbol: encryptValues.includeSymbol
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Numbers", cnt.includeNumber, "top") }} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={5}
                                    inline
                                    label="Upper and lower case"
                                    checked={encryptValues.includeCase}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: e.currentTarget.checked,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: encryptValues.includeSymbol
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Upper and lower", cnt.includeCase, "top") }} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={6}
                                    inline
                                    label="Special characters"
                                    checked={encryptValues.includeSymbol}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: e.currentTarget.checked
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Special characters", cnt.includeSymbol, "top") }} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={7}
                                    inline
                                    label="No special characters"
                                    checked={encryptValues.excludeSymbol}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: e.currentTarget.checked,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: encryptValues.includeNumber
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "No special characters", cnt.excludeSymbol, "top") }} />
                            </div>
                        </Form.Group>
                    </Form.Label>
                    <div className='flexbox grid button-padding'>
                        <Button 
                            tabIndex={8}
                            variant='primary' 
                            size='lg' 
                            type='button' 
                            onClick={e => { e.preventDefault(); e.stopPropagation(); handleSubmit() }} 
                            className='me-2'
                            >Encrypt</Button>
                                
                        <Button 
                            tabIndex={9}
                            variant='secondary' 
                            size='lg' 
                            type='button' 
                            onClick={handleClear}
                            >Cancel</Button>
                    </div>
                </Form>
                <Overlay
                    show={showOverlay}
                    target={target}
                    placement={placement}
                    container={ref}
                    containerPadding={20}
                >
                    <Popover id="popover-contained">
                        <Popover.Header as="h3">{toolTip.title}</Popover.Header>
                        <Popover.Body>
                            {toolTip.text}
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </div>
        </FormHeaderLayout>
    );
}