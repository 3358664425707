import * as React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import Content from '../../common/Content';
import EncryptForm from '../../components/EncryptForm';



export default function Home(){

  return(
    <div className="page-container">
        <Header tagline={Content.Header.tagline}></Header>               
            <EncryptForm />
        <Footer/>       
    </div>      
  )  

}