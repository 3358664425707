

const content = {
    Header: {
        tagline: "Encryption Generator"
    },
    Contact:{
        telephone: "",
        email: "info@encryptiongenerator.com",
        name: "EncryptionGenerator"
    },
    EncryptForm: {
        header: "Generate Password",
        text: "Enter the name of the website/business here. E.g. amazon.co.uk ",
        password: "Enter your own secure password here.",
        password2: "If you are creating a new password you can confirm that you have entered your password correctly by re-entering it here",
        length: "The length of the password that will be generated",
        includeNumber: "Create a password that includes numbers",
        includeCase: "Create a password that includes upper and lower case letters: e.g. AabCdD etc",
        includeSymbol: "Create a password that includes special characters: e.g. % * etc",
        excludeSymbol: "Create a password that does not contain any special characters: e.g. ^$*% etc",
        mustContain: "This is used when you need to generate a password that must contain one of the options below. For instance many sites will stipulate that a password "  +
        "must contain numbers and upper and lower case characters. Bear in mind that most generated passwords will contain these characters anyway."
    },
    Loading: {
        loadingMessage: " ", //Loading",
        stillLoading: " ", //Bear with us, it's taking a bit longer than ususal"
    }

};

export default content;