import './App.scss';
import './scss/style.scss';
import 'bootstrap/scss/bootstrap.scss';
import Router from './Router'



function App() {
  return (
    <Router/>
  );
}

export default App;
